import { TMenuCollectionLink, TMenuLink } from './menu.types'

export const MenuCollectionLinks: TMenuCollectionLink[] = [
  {
    title: 'Bestsellers',
    link: '/shop#bestsellers',
    subTitle: 'Our customer favourites',
    image: '/img/menu/bestsellers.jpg'
  },
  {
    title: 'Limited Editions',
    link: '/shop#chefs-residency',
    subTitle: 'allplants X Rachel Ama',
    image: '/img/menu/limited-editions.jpg'
  },
  {
    isNew: true,
    title: 'Spring Menu',
    link: '/shop#spring-menu',
    subTitle: 'The seasonal collection',
    image: '/img/menu/seasonal-menu.jpg'
  },
  {
    title: 'High in Protein',
    link: '/shop#high-in-protein',
    subTitle: 'Packed with plant power',
    image: '/img/menu/high-in-protein.jpg'
  }
]

export const MenuExtraLinks: TMenuLink[] = [
  {
    title: 'Buddha Bowls',
    link: '/shop#best-sellers'
  },
  {
    title: 'Best Sellers',
    link: '/shop#best-sellers'
  },
  {
    title: 'Best Sellers',
    link: '/shop#best-sellers'
  },
  {
    title: 'Best Sellers',
    link: '/shop#best-sellers'
  }
]

export const MenuDesktopMealLinks: TMenuLink[] = [
  {
    title: 'Buddha Bowls',
    link: '/shop#buddha-bowls'
  },
  {
    title: 'Pastas & Risottos',
    link: '/shop#pastas-risottos'
  },
  {
    title: 'Curries',
    link: '/shop#curries'
  },
  {
    title: 'Noodles',
    link: '/shop#noodles'
  },
  {
    title: 'Stews',
    link: '/shop#stews'
  },
  {
    title: 'Pies & Tarts',
    link: '/shop#pies-and-tarts'
  }
]

export const MenuDesktopExtraLinks: TMenuLink[] = [
  {
    title: 'Side Dishes',
    link: '/shop#sides'
  },
  {
    title: 'Dessert Pots',
    link: '/shop#desserts'
  },
  {
    title: 'Breakfast Pots',
    link: '/shop#breakfast-pots'
  },
  {
    title: 'Smoothies',
    link: '/shop#smoothies'
  }
]
