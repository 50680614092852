import Image from 'next/image'
import { motion } from 'framer-motion'

import { Button } from '@tofu/shared/ui/atoms/button'
import { List, ListItem } from '@tofu/shared/ui/atoms/list'
import { LongArrowRightIcon } from '@tofu/shared/ui/atoms/icons'
import { Container } from '@tofu/shared/ui/atoms/container'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Tag } from '@tofu/shared/ui/atoms/tag'
import { Link } from '@tofu/shop/ui/atoms/link'

import { Box } from '@tofu/shared/ui/atoms/box'
import { HStack, VStack } from '@tofu/shared/ui/atoms/stack'
import {
  MenuCollectionLinks,
  MenuDesktopMealLinks,
  MenuDesktopExtraLinks
} from '@tofu/shop/constants/menu'

import { HeaderDesktopShopButton } from './header-desktop-shop-button'
import { THeaderDesktopShop } from './header-desktop-shop.types'

export const HeaderDesktopShop: THeaderDesktopShop = ({ onMenuItemClick }) => (
  <Box background='white'>
    <Container maxW='1280px'>
      <motion.div
        className='container'
        initial='closed'
        animate='open'
        exit='closed'
      >
        <HStack
          alignItems='start'
          justifyContent='space-between'
          px={5}
          pb={5}
          pt={6}
        >
          <Box key='Collections'>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Collections
            </Text>

            <VStack as={List} spacing={0} alignItems='start'>
              {MenuCollectionLinks.map((item) => (
                <ListItem key={item.link}>
                  <Link
                    href={item.link}
                    name='desktop.nav'
                    label={item.title}
                    passHref
                  >
                    <Button
                      as='span'
                      variant='ghost'
                      width='100%'
                      height='100%'
                      display='block'
                      px={5}
                      py={3}
                      borderRadius={4}
                      onClick={onMenuItemClick}
                    >
                      <HStack>
                        <Image
                          width='64px'
                          height='64px'
                          src={item.image}
                          style={{ borderRadius: '50%' }}
                        />
                        <VStack alignItems='start' spacing={0}>
                          {item.isNew && (
                            <Tag
                              variant='transparent'
                              color='green.500'
                              role='note'
                              size='sm'
                              transform='translateY(3px)'
                            >
                              NEW
                            </Tag>
                          )}
                          <Text
                            as='span'
                            textStyle='body-lg'
                            fontWeight='medium'
                          >
                            {item.title}
                          </Text>
                          <Text
                            as='span'
                            textStyle='body-sm'
                            fontWeight='light'
                            color='grey.700'
                          >
                            {item.subTitle}
                          </Text>
                        </VStack>
                      </HStack>
                    </Button>
                  </Link>
                </ListItem>
              ))}
            </VStack>
          </Box>

          <Box key='main meals'>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Main Meals
            </Text>

            <VStack as={List} spacing={0} alignItems='start'>
              {MenuDesktopMealLinks.map((item) => (
                <ListItem
                  key={item.link}
                  sx={{
                    textDecoration: 'none',
                    '&&:hover': {
                      textDecoration: 'none'
                    }
                  }}
                >
                  <Link
                    href={item.link}
                    name='desktop.nav'
                    label={item.title}
                    passHref
                  >
                    <HeaderDesktopShopButton onClick={onMenuItemClick}>
                      {item.title}
                    </HeaderDesktopShopButton>
                  </Link>
                </ListItem>
              ))}
              <ListItem
                key={'view-more'}
                sx={{
                  textDecoration: 'none',
                  '&&:hover': {
                    textDecoration: 'none'
                  }
                }}
              >
                <Link
                  href='/shop'
                  name='desktop.nav'
                  label='view all dishes'
                  passHref
                >
                  <HeaderDesktopShopButton
                    onClick={onMenuItemClick}
                    textDecoration='underline'
                  >
                    View all dishes
                  </HeaderDesktopShopButton>
                </Link>
              </ListItem>
            </VStack>
          </Box>

          <Box key='extras'>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Extras
            </Text>

            <VStack as={List} spacing={0} alignItems='start'>
              {MenuDesktopExtraLinks.map((item) => (
                <ListItem
                  key={item.link}
                  sx={{
                    textDecoration: 'none',
                    '&&:hover': {
                      textDecoration: 'none'
                    }
                  }}
                >
                  <Link
                    href={item.link}
                    name='desktop.nav'
                    label={item.title}
                    passHref
                  >
                    <HeaderDesktopShopButton onClick={onMenuItemClick}>
                      {item.title}
                    </HeaderDesktopShopButton>
                  </Link>
                </ListItem>
              ))}
            </VStack>
          </Box>
          <VStack alignItems='start'>
            <Link
              href='/shop/bundles/first-taste-bundle'
              name='desktop.nav'
              label='Shop our First Taste Bundle'
              passHref
            >
              <Button
                as='span'
                variant='link'
                fontSize='lg'
                textDecoration='none'
                fontWeight='regular'
                _hover={{
                  textDecoration: 'underline'
                }}
              >
                <VStack spacing={3} alignItems='start'>
                  <Image
                    width='305px'
                    height='292px'
                    src='/img/menu/bestsellers-promo.jpg'
                  />
                  <Text textStyle='body' fontWeight='medium'>
                    Shop our First Taste Bundle{' '}
                    <LongArrowRightIcon ml={3} boxSize={4} />
                  </Text>
                </VStack>
              </Button>
            </Link>
          </VStack>
        </HStack>
      </motion.div>
    </Container>
  </Box>
)
